import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { debounce } from 'debounce';
import cx from 'classnames';
// containers
import { MapSettingsContainer } from 'src/containers/MapSettingsContainer';
import { AddressSearchContainer } from 'src/app/containers/AddressSearchContainer';
// common components
import { StyledMap, MapControl, GeocoderPin, RulerTool, Button } from '@ems/client-design-system';
import { MapReferenceLayers } from 'src/app/components';

import { LocationPopup, OperationMapTags } from 'src/components';
// functions
import { useMapSettings, useRerunHookOnMapBackgroundChange } from 'src/app/functions/mapSettings';
import {
  useMapRef,
  useMapWhenReady,
  useMapProps,
  useMapConfig,
  useMapCenter,
  useSingleDateTile,
} from 'src/app/functions/map';
import {
  useMapLayer,
  useInfringementMap,
  useMapTracksFilter,
} from 'src/app/functions/infringementsOnMap';
import { useConfigSelectors, useLanguageSelectors } from 'src/app/reducers';
// functions
import { useMapReftoCaptureImage } from 'src/app/functions/export';
import { useInAirPlayback } from 'src/utils/playback';
import {
  getStoredMarkers,
  useGeocodePosition,
  goToSelectedAddress,
  addPinToCentre,
  onGeocodingDragEnd,
} from 'src/utils/geocoding';
import { useCircleRanges } from 'src/app/functions/rangeCircle';
import {
  flyTo,
  useHoverOnMapElement,
  useMapHover,
  useMapClick,
  useMapSelection,
  useHoveredPointData,
  useMapStylesForPlayback,
  useMapRuler,
} from 'src/utils';
// constants
import { TOGGLE_MAP_SETTINGS_CTRL, DYNAMIC_TILE_SERVER } from 'src/app/featureToggles';
import { MAP_TYPES } from 'src/constants';
import { ITrackInteractionPoint } from 'src/utils/interfaces';
import { dateTimeInQueryUTC } from 'src/utils/dateTimeConverters';

import { profileTimeToMapVectorLayer, vectorLayerToPoint } from 'src/utils/mapTagHelpers';
import { useRemoveNonPlaybackTracks } from 'src/@operations/hooks/useRemoveNonPlaybackTracks';
import { useTrackSelection } from 'src/utils/mapHelpers/trackHelpers/useTrackSelection';
import { useOperationTrackData } from 'src/utils/mapHelpers/trackHelpers/useOperationTrackData';
import { useMapTags } from 'src/utils/mapHelpers/tags/useMapTags';

import { ProfileGraph } from 'src/components/ProfileGraph';
import { TrackProfileDialog } from '../../components';
import { ProfileGraphButton } from '../MapContainer.styles';

export const MapContainer: FC<{
  time: string;
  operationId: number;
  operation: any;
  position: any;
  currentTime: number;
  isPlaybackMode: boolean;
  isPlaybackRunning: boolean;
  inAirData: any;
  markedTime: number;
  setSelectedTime: any;
  profileHoverTime: number | null;
  setProfileHoverTime: any;
  profileClickTime: number | null;
  setProfileClickTime: any;
}> = ({
  time,
  operationId,
  operation,
  position,
  currentTime,
  isPlaybackMode,
  isPlaybackRunning,
  inAirData,
  markedTime,
  setSelectedTime,
  profileHoverTime,
  setProfileHoverTime,
  profileClickTime,
  setProfileClickTime,
}) => {
    // get map props from config
    const { viewportFromProps, mapStyle: defaultMapStyle, ...mapProps } = useMapProps('2D');
    // map settings
    const {
      mapStyle,
      storeSelectedBackground,
      applyBackground,
      resetBackground,
      layersDisplayed,
      storeSelectedLayers,
      applyLayers,
      resetLayers,
    } = useMapSettings({
      background: defaultMapStyle,
      layers: [],
    });

    const { startTime: operationStartTime, endTime: operationEndTime } = operation || {
      startTime: null,
      endTime: null,
    };

    // used for taking screenshot of map
    const captureRef = useRef(null);
    // map ref
    const [mapNode, mapRef] = useMapRef();
    // get map apis
    const { mapApis, mapLoaded } = useMapWhenReady(mapNode);
    // viewport in state
    const [viewport, setViewport] = useState(viewportFromProps);
    // get mapbox config values required to add source and styles
    const mapBoxConfig = useMapConfig();
    // Configuration
    const configSelectors = useConfigSelectors();
    // get field labels from language selectors
    const languageSelectors = useLanguageSelectors();
    const {
      fields: { operations: opsFields },
      components: {
        headings: { mapSettings: mapSettingsTitle },
        labels: {
          lat: latLabel,
          lng: lngLabel,
          amsl: amslLabel,
          backToCenter: backToCenterLabel,
          ruler: ruler,
        },
      },
      abbreviations,
    } = languageSelectors.getLanguage();

    const labels = Object.assign(opsFields, abbreviations);
    const {
      map: { mapProjectionString },
    } = configSelectors.getConfig();

    // capture map image
    const { enableMapControls } = useMapReftoCaptureImage(captureRef, mapApis);

    useMapCenter(viewport, setViewport, position, operationId);

    // getting map style layers
    const layers = useMapLayer({ mapApis, mapBoxConfig, maptype: 'operationSummary' });
    const FEATURE_FLAG_DYNAMIC_TILE_SERVER = configSelectors.isFeatureAvailable(DYNAMIC_TILE_SERVER);

    /* Returns only the main track when viewing normally
     and shows other flights only during playback */
    const tracksDisplayedOnMap = useMemo(
      () => inAirData.filter(({ id }) => id === operationId || isPlaybackMode),
      [isPlaybackMode, inAirData]
    );

    useRemoveNonPlaybackTracks(mapApis, tracksDisplayedOnMap, isPlaybackMode);
    const rerunHook = useRerunHookOnMapBackgroundChange(mapApis, mapStyle, 2000);
    const { dateString, datesArray } = useSingleDateTile({
      mapApis,
      mapBoxConfig,
      layers,
      time,
      operationId,
      isDynamicTileServer: FEATURE_FLAG_DYNAMIC_TILE_SERVER,
      mapTrackData: tracksDisplayedOnMap,
      rerunHook,
    });

    const { tracks } = useInfringementMap({
      instance: 'op-summary',
      mapApis,
      mapBoxConfig,
      dateString,
      operationId,
      operation,
      infTypeId: null,
      infringementId: 0,
      infringementType: null,
      showTracks: false,
      time,
    });

    const selectedOperationId = useMemo(() => [operationId], []);
    const operationTrackData = useOperationTrackData(mapApis, datesArray, selectedOperationId);
    const { setSelectedOperations, selectedOperations } = useTrackSelection({
      mapApis,
      externallySelectedTracks: operationTrackData,
      rerunHook,
    });

    useMapStylesForPlayback({ mapApis, mapBoxConfig, dateString, isPlaybackMode });

    // extract map filters for the features
    const { infringementFilter } = useMapTracksFilter({ features: tracks });

    // handle hover and get hovered operation
    const { hoveredElement, handleHover, setHoveredElement } = useHoverOnMapElement({
      viewport,
      mapApis,
      layerArray: datesArray,
      tracksFilter: infringementFilter,
      restrictZoomLevels: false,
      mapType: MAP_TYPES.OPERATIONSUMMARY,
    });

    // set map selection for the hover object
    useMapHover(hoveredElement, mapApis);

    const resetView = () => {
      if (mapApis) {
        const resetViewport = Object.assign({}, viewportFromProps, { zoom: viewport.zoom });
        flyTo(mapApis, resetViewport).then(() => {
          setViewport(Object.assign({}, viewport, resetViewport));
        });
      }
    };

    // Map selection
    const [showSelected, setShowSelected] = useState(false);
    const {
      displayedMapTags,
      addTagToMap,
      clearDisplayedTags,
      removeDisplayedTag,
      addTagOnTrackClick,
    } = useMapTags();

    // Clear tags on map when navigation to new operation occurs
    useEffect(() => {
      if (operation) {
        clearDisplayedTags();
      }
    }, [operation]);

    // click handlers that provide selected operations
    const { handleClick } = useMapClick({
      hoveredOperation: hoveredElement,
      setShowSelected,
      mapApis,
      datesArray,
      tracksFilter: infringementFilter,
      selectedOperations,
      setSelectedOperations: addTagOnTrackClick,
      mapType: MAP_TYPES.OPERATIONSUMMARY,
      clearDisplayedTags,
    });

    const [selectedPointData, setSelectedPointData] = useState<ITrackInteractionPoint>({
      amsl: null,
      dist: null,
      time: null,
      longitude: null,
      latitude: null,
      type: null,
      showPointData: false,
      flightId: null,
    });

    useHoveredPointData({
      mapApis,
      operation,
      nearbyFlightsData: inAirData,
      hoveredElement,
      profileHoverTime,
      setSelectedTime,
      setSelectedPointData,
      isPlaybackMode,
      isPlaybackRunning,
      userHomeLocation: null,
      mapProjectionString: null,
    });

    useEffect(() => {
      if (profileClickTime) {
        const selectedLayers = profileTimeToMapVectorLayer(mapApis, operation, profileClickTime);
        const operationLayer = selectedLayers.find(layer => layer.id === operationId);
        // Allow tags to be displayed
        if (showSelected === false) {
          setShowSelected(true);
        }
        addTagToMap(operationLayer);
      }
    }, [profileClickTime]);

    // set feature state for the hovered operation
    useMapSelection(selectedOperations, mapApis, 'infringement-select');

    // reset hover and select when selection changes
    useEffect(() => {
      setShowSelected(false);
      setHoveredElement(null);
      setSelectedOperations([]);
    }, [dateString]);

    useInAirPlayback(mapApis, inAirData, currentTime, isPlaybackMode, operationId);
    // Ruler Tool

    const units = configSelectors.getUnits();

    const { rulerCoordinatesChanged, toggleRuler, isRulerEnabled, rulerCoordinates } = useMapRuler({
      mapApis,
      viewport,
    });

    // restrict map pan
    const onViewportChange = viewport => {
      if (
        Math.abs(viewport.latitude - viewportFromProps.latitude) < mapBoxConfig.limitLatitude &&
        Math.abs(viewport.longitude - viewportFromProps.longitude) < mapBoxConfig.limitLongitude
      ) {
        setViewport(viewport);
      }
    };

    const client = useApolloClient();
    const { addRemoveCircles } = useCircleRanges(mapApis, mapStyle);
    const storedMarkers = getStoredMarkers();
    const [geocoding, updateGeocoding] = useState<{ longitude: number; latitude: number }>(
      storedMarkers ? storedMarkers.main : { longitude: 0, latitude: 0 }
    );
    const [locationAddress, updateLocationAddress] = useState<null | string>(null);
    const { latitude, longitude } = geocoding;
    const { elevation, place } = useGeocodePosition({
      client,
      position: {
        longitude,
        latitude,
      },
    });
    const [closeSearch, updateCloseSearch] = useState<boolean>(false);
    const [isLocationTagOpen, updateLocationTagOpen] = useState<boolean>(false);
    const [drag, updateDragStatus] = useState<boolean>(false);
    const { removeHovered } = useMapHover(hoveredElement, mapApis, drag);

    const AddressSearch = useMemo(
      () => (
        <div className="mapboxgl-ctrl-search">
          <AddressSearchContainer
            source="map"
            onAddressFound={address =>
              goToSelectedAddress({
                address,
                mapApis,
                viewport,
                addRemoveCircles,
                updateGeocoding,
                updateLocationAddress,
                updateLocationTagOpen,
                onViewportChange,
                updateCloseSearch,
              })
            }
          />
        </div>
      ),
      [mapApis, addRemoveCircles]
    );

    const currentLayout = configSelectors.getLayout();
    const isFullScreen = configSelectors.getIsFullscreen();
    const isMapFullscreen = isFullScreen && currentLayout.includes('MAP');
    const isGridFullscreen = isFullScreen && currentLayout.includes('GRID');
    const mapHeight = isMapFullscreen ? 'calc(100vh - 2rem)' : undefined;

    const [isTrackProfileOpen, setIsTrackProfileOpen] = useState(false);
    const selectedTrackTheme = configSelectors.getTheme('operations');

    return (
      <div
        className={cx('map_wrapper', {
          'map_wrapper--fullscreen': isMapFullscreen,
          'map_wrapper--collapsed': isGridFullscreen,
        })}>
        <div ref={captureRef} className="map">
          {isTrackProfileOpen && (
            <TrackProfileDialog
              setIsOpen={setIsTrackProfileOpen}
              isOpen={isTrackProfileOpen}
              selectedOperationIds={selectedOperationId}
            />
          )}
          <StyledMap
            onLoad={() => mapLoaded()}
            viewport={viewport}
            mapStyle={mapStyle}
            onViewportChange={viewport => {
              viewport.maxPitch = 0;
              setViewport(viewport);
            }}
            {...mapProps}
            ref={mapRef}
            onClick={(e: React.MouseEvent) => {
              handleClick(e);
            }}
            onHover={debounce(handleHover, 5)}
            transformRequest={
              mapBoxConfig && mapBoxConfig.transformRequest && mapBoxConfig.transformRequest()
            }
            height={mapHeight}>
            {isLocationTagOpen && (
              <LocationPopup
                latitude={latitude}
                longitude={longitude}
                address={locationAddress || place}
                elevation={elevation}
                languageData={{ latLabel, lngLabel, amslLabel }}
                mapApis={mapApis}
                showFilterButton={false}
                onClose={() => {
                  updateLocationTagOpen(!isLocationTagOpen);
                }}
              />
            )}
            <GeocoderPin
              latitude={enableMapControls ? latitude : 0}
              longitude={enableMapControls ? longitude : 0}
              draggable
              mapApis={mapApis}
              addRemoveCircles={addRemoveCircles}
              onClick={() => {
                updateLocationTagOpen(!isLocationTagOpen);
              }}
              onDragStart={() => {
                addRemoveCircles(null);
                removeHovered();
                updateDragStatus(true);
                updateLocationTagOpen(false);
              }}
              onDragEnd={([longitude, latitude]: number[]) =>
                onGeocodingDragEnd({
                  longitude,
                  latitude,
                  updateDragStatus,
                  updateGeocoding,
                  updateLocationAddress,
                  updateLocationTagOpen,
                  addRemoveCircles,
                })
              }
              onMouseEnter={() => {
                removeHovered();
                updateDragStatus(true);
              }}
              onMouseLeave={() => {
                updateDragStatus(false);
              }}
            />
            {enableMapControls && (
              <MapControl
                isPinAdded={latitude && longitude ? true : false}
                addPinToCentre={() =>
                  addPinToCentre({
                    updateLocationAddress,
                    geocoding,
                    viewport,
                    updateGeocoding,
                    addRemoveCircles,
                    updateLocationTagOpen,
                  })
                }
                translationData={{
                  home: backToCenterLabel,
                  mapSettings: mapSettingsTitle,
                  ruler,
                }}
                navigationControl={{
                  showCompass: false,
                  showHome: true,
                  showSearch: true,
                  showSettings: configSelectors.isFeatureAvailable(TOGGLE_MAP_SETTINGS_CTRL),
                }}
                rulerControl={{
                  isRulerEnabled,
                  toggleRuler,
                }}
                addressSearch={AddressSearch}
                closeSearch={closeSearch}
                resetView={resetView}
                mapSettingsConfig={{
                  update: () => {
                    applyBackground();
                    applyLayers();
                  },
                  reset: () => {
                    resetBackground();
                    resetLayers();
                  },
                  content: (
                    <MapSettingsContainer
                      config={{
                        background: mapStyle,
                        layers: layersDisplayed,
                      }}
                      onUpdate={({ selectedBackground, selectedLayers }) => {
                        if (typeof selectedBackground !== 'undefined') {
                          storeSelectedBackground(selectedBackground);
                        }
                        if (typeof selectedLayers !== 'undefined') {
                          storeSelectedLayers(selectedLayers);
                        }
                      }}
                    />
                  ),
                }}
              />
            )}
            {displayedMapTags.map(({ data, uuid }) => {
              const matchingOperation = inAirData.find(
                operation => operation.id === data.properties.id || operation.id === data.id
              );

              return (
                <OperationMapTags
                  element={data}
                  pointData={vectorLayerToPoint({
                    operation: matchingOperation,
                    clickedElement: data,
                  })}
                  labels={labels}
                  draggable
                  onClose={() => removeDisplayedTag(uuid)}
                  key={uuid}
                />
              );
            })}

            {hoveredElement && !profileHoverTime && (!isPlaybackMode || !isPlaybackRunning) && (
              <OperationMapTags
                element={hoveredElement}
                pointData={selectedPointData}
                labels={labels}
                draggable={false}
              />
            )}
            <MapReferenceLayers
              mapApis={mapApis}
              mapStyle={mapStyle}
              layers={layersDisplayed}
              dateRange={{
                dateFilterFrom: dateTimeInQueryUTC(new Date(operationStartTime), 'start'),
                dateFilterTo: dateTimeInQueryUTC(new Date(operationEndTime), 'end'),
              }}
            />
            <RulerTool
              distanceUnits={units.distance}
              coordinates={rulerCoordinates}
              isRulerEnabled={isRulerEnabled}
              mapProjection={mapProjectionString}
              handleDragEvent={rulerCoordinatesChanged}
              mapApis={mapApis}
            />
          </StyledMap>
        </div>
        <div className="map-overlay-panel">
          <ProfileGraph
            data={operation.profile}
            operationType={operation.operationType}
            currentTime={0}
            startTime={operation.startTime}
            endTime={operation.endTime}
            shouldDisplay={operation && operation.profile}
            markedTime={markedTime}
            onHoverCB={setProfileHoverTime}
            selectedTrackTheme={selectedTrackTheme}
            onClick={setProfileClickTime}
          />
          <ProfileGraphButton>
            <Button
              style="primary"
              size="s"
              className="track-profiles-btn"
              onClick={() => setIsTrackProfileOpen(true)}>
              Plot Profile
            </Button>
          </ProfileGraphButton>
        </div>
      </div>
    );
  };
