// Hooks
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useAircraftMappings } from '../providers';

// Types
import {
  IAircraftMappingEdge,
  ISetAircraftMappingResponse,
  ISetAircraftMappingVars,
  IAircraftMappingResponse,
  IAircraftMappingVars,
  AirQualityMappingSource,
} from '../types';

// Queries
import { GET_AIRCRAFT_MAPPINGS, SET_AIRCRAFT_MAPPING } from '../queries/aircraftMappings';

// Components
import { displayToast } from '@ems/client-design-system';
import { useLanguageSelectors } from 'src/app/reducers';
import { UNMAPPED_STRING_PLACEHOLDER } from 'src/constants';

export const matchResponseToEdge = (
  edges: IAircraftMappingEdge[],
  data: ISetAircraftMappingResponse
) =>
  edges.find(
    ({ node }) =>
      node.aircraftType === data.setAirQualityAircraftTypeMap.aircraftType &&
      node.engineModel === data.setAirQualityAircraftTypeMap.engineModel
  );

export const useSetAircraftMapping = ({
  queryVariables,
  scenarioId,
}: {
  queryVariables: IAircraftMappingVars;
  scenarioId: number;
}) => {
  const languageSelector = useLanguageSelectors();
  const {
    components: {
      success: { setAircraftMappingsSuccess: setAircraftMappingsSuccessString },
      errors: { setAircraftMappingsInvalid: setAircraftMappingsInvalidString },
    },
  } = languageSelector.getLanguage();
  const {
    dispatch,
    state: {
      data: { edges },
    },
  } = useAircraftMappings();
  const [mutate, { data, loading, error }] = useMutation<
    ISetAircraftMappingResponse,
    ISetAircraftMappingVars
  >(SET_AIRCRAFT_MAPPING, {
    update: (cache, { data }) => {
      const store: IAircraftMappingResponse = cache.readQuery({
        query: GET_AIRCRAFT_MAPPINGS,
        variables: queryVariables,
      });
      const {
        airQualityAircraftMappingSummariesByTimeRange: { edges },
      } = store;

      // Find matching node in edges based on engine and aircraft type

      const matchedEdge = matchResponseToEdge(edges, data);

      if (matchedEdge) {
        matchedEdge.node = {
          ...matchedEdge.node,
          ...data.setAirQualityAircraftTypeMap,
        };

        // Get the index for store
        const updatedEdges = [...edges];
        const matchedNodeIndex = updatedEdges.findIndex(
          ({ cursor }) => cursor === matchedEdge.cursor
        );

        if (matchedNodeIndex !== -1) {
          updatedEdges[matchedNodeIndex] = matchedEdge;
        }

        const queryData = store;
        queryData.airQualityAircraftMappingSummariesByTimeRange.edges = [...updatedEdges];

        // Update the cache with the new matched response
        cache.writeQuery({
          query: GET_AIRCRAFT_MAPPINGS,
          data: queryData,
        });
      }
    },
  });

  const setMapping = useCallback(
    ({ edge }: { edge: IAircraftMappingEdge }) => {
      const {
        aircraftType,
        engineModel,
        mappedLTOAircraftEngine,
        mappedEnrouteAircraftEngine,
      } = edge.node;
      const [ltoAircraftType, ltoEngineModel] = mappedLTOAircraftEngine.split(' / ');
      const [enrouteAircraftType, enrouteEngineModel] = mappedEnrouteAircraftEngine.split(' / ');

      if (
        mappedLTOAircraftEngine === UNMAPPED_STRING_PLACEHOLDER ||
        mappedEnrouteAircraftEngine === UNMAPPED_STRING_PLACEHOLDER
      ) {
        displayToast({
          key: 'mappingToast',
          message: setAircraftMappingsInvalidString,
          intent: 'danger',
          timeout: 3000,
        });
        dispatch({ type: 'TABLE_SET_INVALID_ROW', data: [edge.cursor] });
        return null;
      }

      const variables: ISetAircraftMappingVars = {
        scenarioId,
        aircraftType,
        engineModel,
        ltoAircraftType,
        ltoEngineModel,
        enrouteAircraftType,
        enrouteEngineModel,
        source: AirQualityMappingSource.USER,
      };

      // Loop through and null (remove) any instances of "?"
      const cleanedVars = Object.fromEntries(
        Object.entries(variables).filter(([, value]) => value !== '?')
      ) as ISetAircraftMappingVars;

      mutate({
        variables: cleanedVars,
      })
        .then(response => {
          const matchedEdge = matchResponseToEdge(edges, response.data);
          dispatch({ type: 'UPDATE_MAPPING_NODE', edge: matchedEdge });

          displayToast({
            key: 'mappingToast',
            message: setAircraftMappingsSuccessString,
            intent: 'success',
            timeout: 3000,
          });
        })
        .catch(error => {
          console.error('Error setting mapping:', error);
        });
    },
    [mutate]
  );

  return [setMapping, { data, loading, error }] as const;
};
